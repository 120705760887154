import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form is submitted

    axios
      .post("https://vbackend.vercel.app/api/send-contact", formData)
      .then((response) => {
        console.log("SUCCESS!", response.data);
        alert("Your message has been sent!");
      })
      .catch((err) => {
        console.error("FAILED...", err);
        alert(
          "There was an error sending your message. Please try again later."
        );
      })
      .finally(() => {
        setLoading(false); // Set loading to false after request is completed
      });

    setFormData({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <div className="container">
      <Helmet>
        <title>Contact Us | Versatile Nannies</title>
      </Helmet>
      <Header />
      <div className="contact-us">
        <h2>Contact Us</h2>
        {loading ? ( // Show loading message or spinner if loading is true
          <div className="loading">
            <p>Sending your message...</p>
            {/* You can also add a spinner here */}
            {/* <div className="spinner"></div> */}
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>First Name:</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone:</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Subject:</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Your Message:</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
