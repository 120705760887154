import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Process.css";
import kids1 from "../../files/img/kids1.jpg";
import kids2 from "../../files/img/kids2.jpg";
import kids3 from "../../files/img/kids3.jpg";
import { Link } from "react-router-dom";

const Process = () => {
  return (
    <div className="process-container">
      <Helmet>
        <title>Our Process | Versatile Nannies</title>
      </Helmet>
      <Header />
      <div className="process-content">
        <h1 className="process-title">OUR PROCESS</h1>
        <div className="process-steps">
          <p>
            <strong>Step 1:</strong> The first step is a consultation with
            families, where we discuss your needs and preferences, review our
            nanny introduction process and answer any questions you may have.
          </p>
          <p>
            <strong>Step 2:</strong> To move forward, we will review and sign
            together our service agreement which initiates the nanny matching
            process. While we may currently have a candidate in process that
            might be a good fit, we are prepared to search for the most
            appropriate candidates for your family. Each family's needs are
            unique and the entire process typically ranges from a few hours to
            six weeks in duration.
          </p>
          <p>
            <strong>Step 3:</strong> Next arrangements will be made for you to
            personally interview a matching nanny.
          </p>
          <p>
            <strong>Step 4:</strong> We then prepare an employment agreement
            describing duties, hours, pay, and the expectations of both parties.
          </p>
        </div>

        <h1 className="process-title">WHY FAMILIES CHOOSE VersaNanny</h1>
        <div className="process-steps">
          <p>
            <strong>Flexibility</strong> - We hire nannies that live nearby and
            are comfortable caring for your little ones when you need them
            (weekends, evenings, afternoons, etc.).
          </p>
          <p>
            <strong>Passionate</strong> - We hire nannies that are passionate
            about engaging children and providing excellent childcare service.
          </p>
          <p>
            <strong>Personalization</strong> - We hire nannies that are
            comfortable caring for children based on the guidelines and
            preferences set by their parents/guardians.
          </p>
        </div>

        <div className="contactUsbutton">
          <Link to="/contact">Contact Us</Link>
        </div>

        <h1 className="process-title">ADDITIONAL INFORMATION</h1>
        <div className="process-steps">
          <p>
            <strong> CONSULTATION:</strong> When you are ready to move forward
            and would like to schedule a consultation, we have day or evening
            appointment times available.
          </p>
        </div>
        <div className="imgContainer">
          <div>
            <img src={kids1} alt="Kids 1" />
          </div>
          <div>
            <img src={kids2} alt="Kids 2" />
          </div>
          <div>
            <img src={kids3} alt="Kids 3" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Process;
