import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import fromHere from "../../files/img/fromHere.webp";
import owner from "../../files/img/owner.jpg";
import activities1 from "../../files/img/activities1.jpg";
import activities2 from "../../files/img/activities2.jpg";
import activities3 from "../../files/img/activities3.jpg";
import "./About.css";

const About = () => {
  return (
    <div className="container">
      <Helmet>
        <title>About Us | Versatile Nannies</title>
      </Helmet>
      <Header />
      <div className="container">
        <div className="about-container">
          <section className="about-section">
            <h1>About Us</h1>
            <p>
              Our vision is to establish a personalized passionate nanny service
              based on each family's specific needs and preferences. We hire
              versatile nannies that are passionate about caring for children.
              While technology has impacted most businesses, at VersaNanny we
              maintain that the most sincere and meaningful relationships begin
              with listening and learning. We endeavor to meet each family and
              each nanny to establish a working relationship together.
              VersaNanny is committed to personalized service based on the
              requirements, environment and preferences of our client. We are
              dedicated to supporting families, family values, and childcare
              providers.
            </p>
          </section>

          <section className="activities-section">
            <h2>Our Activities</h2>
            <div className="activities-container">
              <img
                src={activities1}
                alt="Activity 1"
                className="activity-image"
              />
              <img
                src={activities2}
                alt="Activity 2"
                className="activity-image"
              />
              <img
                src={activities3}
                alt="Activity 3"
                className="activity-image"
              />
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
