import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import teaching1 from "../../files/img/teaching1.jpg";
import teaching2 from "../../files/img/teaching2.jpg";
import teaching3 from "../../files/img/teaching3.jpg";
import "./Seeking.css";
import ApplyForm from "../../components/ApplyForm/ApplyForm"; // Import the form component

const Seeking = () => {
  const [showForm, setShowForm] = useState(false);

  const handleApplyClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <div className="seeking-container">
      <Helmet>
        <title>Seeking a Position | Versatile Nannies</title>
      </Helmet>
      <div className="headercontainer">
        <Header />
      </div>

      <div className="seeking-content">
        <h1 className="seeking-title">SEEKING A POSITION</h1>
        <p className="seeking-text">
          Welcome! We are happy that you are interested in employment
          opportunities through VersaNanny. There are many benefits of working
          with us! We want to get to know you and what you're comfortable with.
          We maintain relationships with many of our nannies over the years.
          When they complete one position successfully, we are pleased to refer
          them to a new family to assist. The families we work with are
          pre-screened, usually through personal interviews. It is important to
          us to facilitate a good match between candidates and families. Once
          you’ve accepted a position with a family client, we will communicate
          all expectations so that you have a clear picture of your
          responsibilities. As a nanny, you will work as a household employee of
          the family that hires you, but even after that, you can count on us
          for ongoing support and respect for you as a professional.
        </p>

        <div className="seeking-requirements">
          <h2>REQUIREMENTS FOR SEEKING A POSITION</h2>
          <ul>
            <li>
              Be 16 years of age or older and possess a genuine desire to
              nurture children or provide compassionate care to elders
            </li>
            <li>
              Possess the physical and emotional stamina to provide care for
              others
            </li>
            <li>
              Related education in the area of child development is a plus,
              though not required
            </li>
            <li>
              Clean criminal background check and a motor vehicle driving record
              that indicates safe driving habits
            </li>
            <li>Non-smoking per request of our client families</li>
            <li>
              Respect each child or adult client as an individual and endeavor
              to create a safe and healthy environment promoting the physical,
              emotional, social, and intellectual well-being of children / adult
              clients
            </li>
            <li>Willingness to submit to pre-employment drug screening</li>
          </ul>
        </div>

        <p className="seeking-cost">
          <strong>COST FOR SEEKING A POSITION:</strong> The only expenses a
          candidate has are the cost of completing your background checks and
          CPR and First Aid Certification and any additional childcare training
          that you may choose to complete. If you haven’t had a criminal
          background check or driving record check recently, you’ll be asked to
          update it. Our referral service to you is free of charge. The families
          we serve contract with us to find wonderful nannies, assistants, and
          caregivers, and we work to find just the right position for the
          qualified candidates that seek positions through our agency. We look
          forward to meeting you!
        </p>

        <p className="seeking-next-steps">
          <strong>I MEET THE ABOVE REQUIREMENTS, WHAT DO I DO NEXT?</strong> As
          a first step, please email your updated childcare / nanny, family
          assistant or caregiver–related resume to{" "}
          <a href="mailto:hello@versananny.com">hello@versananny.com</a>. We
          will review these items and be in contact about next steps. You may
          contact us by phone or email if you have specific questions.
        </p>

        <div className="seeking-salary-expectations">
          <h2>SALARY EXPECTATIONS</h2>
          <p>
            The salary you can expect depends upon your experience, education,
            and the specific responsibilities of your position. Many families
            offer paid time off, and some full-time positions offer a
            contribution to health benefits. We strive to work with families
            that value the unique contribution a nanny or caregiver makes to
            their family and are prepared to pay a sustainable wage.
          </p>
        </div>

        <div className="apply-now-button">
          <button className="apply-now-link" onClick={handleApplyClick}>
            Apply Now
          </button>
        </div>
      </div>
      <div className="imgContainer">
        <div>
          <img src={teaching1} alt="" />
        </div>
        <div>
          <img src={teaching2} alt="" />
        </div>
        <div>
          <img src={teaching3} alt="" />
        </div>
      </div>
      <Footer />

      <div className="apply-form"
      >{showForm && <ApplyForm onClose={handleCloseForm} />}</div>
    </div>
  );
};

export default Seeking;
