import React from "react";
import "./Footer.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


const Footer = () => {
  return (
    <div className="footer">
      <footer>
        <div className="footer-content">
          <h1>
            Let us serve your family with passionate and personalized childcare!
          </h1>
          <h3>(617) 712.6553 | hello@versananny.com</h3>
          <h3>Monday - Friday | 8:00a - 8:00p</h3>
          <div className="social-icons">
            <a href="#" className="social-icon">
              <i className="fab fa-facebook-f fa-2x"></i>
            </a>
            <a href="#" className="social-icon">
              <i className="fab fa-instagram fa-2x"></i>
            </a>
            <a href="#" className="social-icon">
              <i className="fab fa-linkedin-in fa-2x"></i>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
