import React, { useState, useRef } from "react";
import "./ApplyForm.css";
import axios from "axios";

const ApplyForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
    resume: null,
  });

  const [loading, setLoading] = useState(false); // Add loading state

  const formRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form is submitted

    const formDataObj = new FormData();
    formDataObj.append("firstName", formData.firstName);
    formDataObj.append("lastName", formData.lastName);
    formDataObj.append("phone", formData.phone);
    formDataObj.append("email", formData.email);
    formDataObj.append("subject", formData.subject);
    formDataObj.append("message", formData.message);
    if (formData.resume) {
      formDataObj.append("resume", formData.resume);
    }

    try {
      const response = await axios.post(
        "https://vbackend.vercel.app/api/send",
        formDataObj
      );
      console.log("SUCCESS!", response.status, response.data);
      alert("Your message has been sent!");
    } catch (err) {
      console.error("FAILED...", err);
      alert("There was an error sending your message. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false after request is completed
    }

    setFormData({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      subject: "",
      message: "",
      resume: null,
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset file input value
    }
  };

  return (
    <div className="apply-form-overlay">
      <div className="apply-form-container">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Apply Now</h2>
        {loading ? ( // Show loading message or spinner if loading is true
          <div className="loading">
            <p>Sending your application...</p>
            {/* You can also add a spinner here */}
            {/* <div className="spinner"></div> */}
          </div>
        ) : (
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="form-group">
              <label>First Name:</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone:</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Subject:</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Your Message:</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Resume:</label>
              <input
                type="file"
                name="resume"
                onChange={handleFileChange}
                ref={fileInputRef}
                required
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ApplyForm;
