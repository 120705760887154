// src/Home.js

import React from "react";
import { Helmet } from "react-helmet";
import "./Home.css";

import logo from "../../files/img/logo.png";
import motherChild from "../../files/img/motherChild.webp";
import family1 from "../../files/img/family1.jpg";
import family2 from "../../files/img/family2.jpg";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>HOME | Versatile Nannies</title>
      </Helmet>
      <div className="header">
        <Header />
      </div>

      <div className="container">
        <div className="motherChildContainer">
          <img src={motherChild} alt="Mother and child" />
        </div>
        <div className="textContainer1">
          Since our beginning in 2005, our mission has been to provide
          experienced and screened nannies, family assistants, and caregivers to
          our client families. It is our purpose to honor each family’s
          preferences and needs, providing options and support as they select
          and hire the best candidate for their family. Whether your care needs
          are long-term, short-term, live-out, live-in, occasional, or for your
          family's hotel stay or travel needs, we can help.
        </div>
        <div className="line"></div>
        <div className="familyContainer">
          <Link to="/process">
            <div className="family1">
              <img src={family1} alt="Family 1" />
              <h3 className="text">Start your search</h3>
            </div>
          </Link>
          <Link to="/seeking">
            <div className="family2">
              <img src={family2} alt="Family 2" />
              <h3 className="text">Seeking a position</h3>
            </div>
          </Link>
        </div>
      </div>

      <div className="Footer">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
