import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../files/img/logo.png";
import logo_b from "../../files/img/versananny-b.png";
import "./Header.css"; // Import the CSS file

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbarCom">
      <div className="logo">
        <img src={logo_b} alt="Logo" />
      </div>
      <div className="headerContainer">
        <header>
          <div className="hamburger" onClick={toggleMenu}>
            <div className={`line ${isOpen ? "open" : ""}`}></div>
            <div className={`line ${isOpen ? "open" : ""}`}></div>
            <div className={`line ${isOpen ? "open" : ""}`}></div>
          </div>
        </header>
      </div>
      <nav className={`navbar ${isOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link to="/" className="/home" href="#">
              Home
            </Link>
          </li>
          <li>
            <Link to="/process">Our Process | Screening</Link>
          </li>
          <li>
            <Link to="/seeking">Seeking a Position</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Header;
